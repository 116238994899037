<script setup lang="ts">
import UpIcon from '~/assets/icons/direction/up.svg'

const route = useRoute()

const { isAuthenticated } = useAuthorization()

const isFooterExpanded = ref(false)
const isNavbarCollapsed = ref(false)
</script>

<template>
  <div class="flex">
    <n-layout position="absolute" has-sider>
      <n-layout-sider
        v-if="isAuthenticated"
        class="hidden md:block"
        :native-scrollbar="false"
        collapse-mode="width"
        :collapsed-width="75"
        :collapsed="isNavbarCollapsed"
        show-trigger="arrow-circle"
        bordered
        @collapse="isNavbarCollapsed = true"
        @expand="isNavbarCollapsed = false"
      >
        <TheSidebar
          :current-path="route.path"
          :is-collapsed="isNavbarCollapsed"
          class="h-full w-full min-h-screen"
        />
      </n-layout-sider>
      <n-layout-content>
        <slot />

        <template v-if="isAuthenticated">
          <!-- Floating Userbar -->
          <div class="fixed flex flex-col md:hidden bottom-0 w-full">
            <TheUserBar @toggle="isFooterExpanded = !isFooterExpanded" />
          </div>

          <div
            v-if="isFooterExpanded"
            class="h-screen w-screen fixed md:hidden bg-black/20 top-0 overflow-auto z-[1001]"
          >
            <div class="flex flex-col gap-0 h-full">
              <div class="grow bg-white rounded overflow-hidden m-2 mb-0">
                <TheSidebar
                  :current-path="route.path"
                  :is-mobile="true"
                  class="w-full h-full overflow-y-auto"
                  @select="isFooterExpanded = false"
                />
              </div>
              <TheUserBar @toggle="isFooterExpanded = !isFooterExpanded" />
            </div>
          </div>
        </template>
        <n-back-top :right="20">
          <UpIcon class="p-3" />
        </n-back-top>
      </n-layout-content>
    </n-layout>
  </div>
</template>
