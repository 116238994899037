<script lang="ts" setup>
const props = defineProps<{
  currentPath: string
  isCollapsed?: boolean
  isMobile?: boolean
}>()
const emit = defineEmits<{ (e: 'select'): void }>()

const { menuOptions, footerOptions } = useMenu()
const { pageTitle, currentRoute } = useRouteTitle(computed(() => props.currentPath))

const logoWidth = computed(() => {
  if (props.isCollapsed) {
    return '50px'
  }
  return props.isMobile ? '200px' : '225px'
})

useHead({
  title: pageTitle,
})
</script>

<template>
  <div class="flex flex-col justify-between h-full">
    <div class="shrink-0 ">
      <div class="flex justify-center pt-5 pb-2">
        <TheLogo
          :show-icon="isCollapsed"
          :show-logo="!isCollapsed"
          :show-text="!isCollapsed"
          :max-width="logoWidth"
          text-font-size="15px"
        />
      </div>
      <n-menu
        :options="menuOptions"
        :value="currentRoute"
        :collapsed="isCollapsed"
        :collapsed-width="75"
        :collapsed-icon-size="22"
        :root-indent="20"
        :indent="10"
        accordion
        :class="isCollapsed ? 'mt-10' : ''"
        @update:value="emit('select')"
      />
    </div>
    <div>
      <n-menu
        :options="footerOptions"
        :value="currentRoute"
        :collapsed="isCollapsed"
        :collapsed-width="70"
        :collapsed-icon-size="22"
        :root-indent="20"
        :render-icon="({ icon }) => (icon && isCollapsed) && icon()"
        accordion
        :theme-overrides="{ itemHeight: '35px' }"
        class="shrink-0"
      />
      <TheLanguageSelector
        class="mb-5 mx-3 md:mb-3 md:mx-4"
        :class="isCollapsed && 'flex justify-center'"
        :component="isCollapsed ? 'dropdown' : 'select'"
      />
    </div>
  </div>
</template>

<style>
.n-dropdown-option-body__prefix--show-icon {
  padding: 5px;
  padding-left: 10px;
  padding-right: 8px;
}
</style>
