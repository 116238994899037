const MENU_ITEM_SEPERATOR = '-'

export default (currentRoute: ComputedRef<string>) => {
  const { $i18n } = useNuxtApp()
  const { findMatchingOption } = useMenu()

  const appName = $i18n.t('app.title')
  const pageTitle = computed(() => {
    if (currentRoute.value === '/') {
      return appName
    }

    const rootLevelPartialMatch = findMatchingOption(currentRoute.value, true)
    if (!rootLevelPartialMatch) {
      return appName
    }

    // 1. Do we have an exact top level match?
    const { key, labelText: labelTextRoot, children } = rootLevelPartialMatch
    if (key === currentRoute.value) {
      if (labelTextRoot) {
        return `${labelTextRoot} ${MENU_ITEM_SEPERATOR} ${appName}`
      }
      return appName
    }

    // 2. Does one of the children match? This time it needs to be exact as we only allow one level of children
    const secondLevelExactMatch = findMatchingOption(currentRoute.value, false, children || [])
    if (!secondLevelExactMatch) {
      return appName
    }

    const { key: keyChild, labelText: labelTextChild } = secondLevelExactMatch
    if (keyChild === currentRoute.value) {
      if (labelTextRoot && labelTextChild) {
        return `${labelTextChild} ${MENU_ITEM_SEPERATOR} ${appName}`
      }
      if (labelTextRoot || labelTextChild) {
        return `${labelTextRoot || labelTextChild} ${MENU_ITEM_SEPERATOR} ${appName}`
      }
    }

    return appName
  })

  return { appName, pageTitle, currentRoute }
}
