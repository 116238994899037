<script lang="ts" setup>
defineEmits(['toggle'])

const { formattedFullname } = useAuthorization()
</script>

<template>
  <div class="flex items-center justify-between gap-2 m-2 p-2 rounded border bg-white border-gray-300 z-50">
    <div class="flex items-center gap-2">
      <n-button quaternary type="primary" class="!p-1" @click="$emit('toggle')">
        <Icon name="solar:hamburger-menu-linear" size="30" />
      </n-button>
      <p>
        {{ formattedFullname }}
      </p>
    </div>
    <NuxtLink to="/auth/logout">
      <n-button quaternary type="primary" class="!p-1">
        <Icon name="uil:exit" size="23" />
      </n-button>
    </NuxtLink>
  </div>
</template>
